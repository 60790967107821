import { useState, useEffect } from "react";
import { useGeolocated } from "react-geolocated";

import "./style.css";

export default function CipherCompass() {
  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false
    },
    userDecisionTimeout: 5000
  });

  const [pointDegree, setPointDegree] = useState(0);
  const [compassCircleTransformStyle, setCompassCircleTransform] = useState(
    "translate(-50%, -50%)"
  );
  const [myPointStyle, setMypointStyle] = useState(0);

  const locationHandler = (coords: GeolocationCoordinates) => {
    const { latitude, longitude } = coords;
    const resP = calcDegreeToPoint(latitude, longitude);
    console.log("resP", resP);
    if (resP < 0) {
      setPointDegree(resP + 360);
    } else {
      setPointDegree(resP);
    }
  };

  useEffect(() => {
    if (!isGeolocationAvailable) {
      alert("Your browser does not support Geolocation");
    } else if (!isGeolocationEnabled) {
      alert(
        "Geolocation is not enabled, Please allow the location check your setting"
      );
    } else if (coords) {
      locationHandler(coords);
    }
  }, [coords, isGeolocationAvailable, isGeolocationEnabled]);
  const isIOS = () => {
    return (
      navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
      navigator.userAgent.match(/AppleWebKit/)
    );
  };

  const calcDegreeToPoint = (latitude: number, longitude: number) => {
    // Qibla geolocation
    // nuovo mondo 45.58538952473806, 11.25632057112174
    // piron : 45.58446290096483, 11.260243884614011
    // dalla valle: 45.583490961159704, 11.258738613110037
    // morello caffé: 45.28178482330486, 11.62366948327371
    const point = {
      lat: 45.28178482330486,
      lng: 11.62366948327371
    };

    const phiK = (point.lat * Math.PI) / 180.0;
    const lambdaK = (point.lng * Math.PI) / 180.0;
    const phi = (latitude * Math.PI) / 180.0;
    const lambda = (longitude * Math.PI) / 180.0;
    const psi =
      (180.0 / Math.PI) *
      Math.atan2(
        Math.sin(lambdaK - lambda),
        Math.cos(phi) * Math.tan(phiK) -
          Math.sin(phi) * Math.cos(lambdaK - lambda)
      );
    return Math.round(psi);
  };

async function startCompassListener(callback: (compass: number) => void) {
  if (!window["DeviceOrientationEvent"]) {
      console.warn("DeviceOrientation API not available");
      return;
  }
  let absoluteListener = (e: any) => {
      if (!e.absolute || e.alpha == null || e.beta == null || e.gamma == null)
          return;
      let compass = -(e.alpha + e.beta * e.gamma / 90);
      compass -= Math.floor(compass / 360) * 360; // Wrap into range [0,360].
      window.removeEventListener("deviceorientation", webkitListener);
      callback(compass);
  };
  let webkitListener = (e: any) => {
      let compass = e.webkitCompassHeading;
      if (compass!=null && !isNaN(compass)) {
          callback(compass);
          window.removeEventListener("deviceorientationabsolute", absoluteListener);
      }
  }

  function addListeners() {
      // Add both listeners, and if either succeeds then remove the other one.
      window.addEventListener("deviceorientationabsolute", absoluteListener);
      window.addEventListener("deviceorientation", webkitListener);
  }
  interface DeviceOrientationEventiOS extends DeviceOrientationEvent {
    requestPermission?: () => Promise<'granted' | 'denied'>;
  }
  
  const requestPermission = (DeviceOrientationEvent as unknown as DeviceOrientationEventiOS).requestPermission;
  
  if (typeof requestPermission === 'function') {
    requestPermission()
          .then(response => {
              if (response == "granted") {
                  addListeners();
              } else
                  console.warn("Permission for DeviceMotionEvent not granted");
          });
  } else
      addListeners();
}

const handler = (compass: number) => {
    //const compass = Math.abs(e.alpha ?? 0 - 360);
    const compassCircleTransform = `translate(-50%, -50%) rotate(${-compass}deg)`;
    setCompassCircleTransform(compassCircleTransform);

    // ±15 degree
    if (
      (pointDegree < Math.abs(compass) &&
        pointDegree + 15 > Math.abs(compass)) ||
      pointDegree > Math.abs(compass + 15) ||
      pointDegree < Math.abs(compass)
    ) {
      setMypointStyle(0);
    } else if (pointDegree) {
      setMypointStyle(1);
    }
  };
  console.log("coords:", coords);

  function GianBeer() {
    if (myPointStyle==1) {
    return(
      <div>Vai da Gianluca a berti una birra :D</div>
    );
  }  else return null;
  }

  return (
    <div className="App">
      <div>myPointStyle:{myPointStyle}</div>
      <div>pointDegree:{pointDegree}</div>
      <div>coords?.latitude:{coords?.latitude}</div>
      <div>coords?.longitude:{coords?.longitude}</div>
      <h1>Premi su start compass e gira finché non appare il pallino verde</h1>
      <div className="compass">
        <div className="arrow" />
        <div
          className="compass-circle"
          style={{ transform: compassCircleTransformStyle }}
        />
        <div className="my-point" style={{ opacity: myPointStyle }} />
      </div>
      <button className="start-btn" onClick={() => startCompassListener(handler)}>
        Start compass
      </button>
      <GianBeer/>
    </div>
  );
}


